import { motion, AnimatePresence } from 'framer-motion';
import { CSSProperties, useContext, useMemo } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { DescriptionTextKpiPoint, DescriptionTextValue } from '../../../functions/src/common/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';
import ListeningView from './ListeningView';

export const PromisesView = () => {
  const speechConfig = useContext(SpeechConfigContext);

  const [promiseKpis, loading] = useKpiCollection<DescriptionTextKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Promises',
    initialState: [] as DescriptionTextKpiPoint[],
  });

  const promises = useMemo(() => {
    const allPromises = promiseKpis.reduce<DescriptionTextValue[]>((acc, kpi) => {
      return [...acc, ...kpi.value];
    }, []);

    //filter out objects with the same text
    return allPromises.filter((value, index, self) => self.findIndex(t => t.text === value.text) === index).reverse();
  }, [promiseKpis]);

  if (promiseKpis.length === 0 || loading) {
    return <ListeningView />;
  }

  return (
    <AnimatePresence>
      <div className="mt-4 flex w-full flex-col items-center gap-y-2">
        {promises.map((promise, i) => (
          <motion.div
            layout
            key={`promise-${promise.text} `}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
            className="w-[95%]">
            <div
              className="flex flex-col overflow-hidden rounded-b-[0.4375rem] shadow-promise"
              style={
                {
                  transform: `rotate(${i % 2 === 0 ? -4.5 : 1.5}deg)`,
                } as CSSProperties
              }>
              <div
                className={
                  'flex items-center justify-between rounded-t-[0.4375rem] bg-partyBgColor px-3 py-[0.75rem] text-partyColor'
                }>
                <div className="text-[0.625rem] font-bold leading-none">{`Löfte #${promises.length - i}`}</div>
                <div className="font-normal text-[0.625rem] leading-none">{speechConfig?.party}</div>
              </div>
              <div className="bg-eidraLightGrey p-5 text-eidraBlack">
                <span className={'block text-[1.75rem] font-medium leading-[1.2]'}>&quot;{promise.text}&quot;</span>

                <span>{promise.description}</span>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );
};
