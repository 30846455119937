import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useMemo } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { DescriptionTextKpiPoint, DescriptionTextValue } from '../../../functions/src/common/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';
import ListeningView from './ListeningView';

export const VerbiagesView = () => {
  const speechConfig = useContext(SpeechConfigContext);
  const [verbiageKpis] = useKpiCollection<DescriptionTextKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Verbiages',
    initialState: [] as DescriptionTextKpiPoint[],
  });

  const verbiages = useMemo(() => {
    const allVerbiages = verbiageKpis.reduce<DescriptionTextValue[]>((acc, kpi) => {
      return [...acc, ...kpi.value];
    }, []);

    //filter out objects with the same text
    return allVerbiages.filter((value, index, self) => self.findIndex(t => t.text === value.text) === index).reverse();
  }, [verbiageKpis]);

  if (verbiages.length === 0 || !speechConfig) {
    return <ListeningView />;
  }
  return (
    <div className="space-y-8">
      <AnimatePresence>
        {verbiages.map((verbiageValue, i) => (
          <motion.div
            layout
            key={verbiageValue.text}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}>
            <Verbiage
              verbiage={verbiageValue.text}
              description={verbiageValue.description}
              index={verbiages.length - i}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

const Verbiage = ({
  verbiage: phrase,
  description: explanation,
  index,
}: {
  verbiage: string;
  description: string;
  index: number;
}) => {
  return (
    <div className={`mt-4 flex flex-col gap-2`}>
      <div className="flex flex-row items-center">
        <div className="flex aspect-square h-8 items-center justify-center rounded-full border-[0.1875rem] border-eidraWhite bg-partyBgColor text-partyColor">
          <span className="text-center font-medium">{index}</span>
        </div>
        <div className="relative flex flex-auto items-center bg-eidraWhite px-3 py-1 before:absolute before:-left-4 before:bottom-0 before:top-0 before:-z-10 before:block before:w-4 before:bg-eidraWhite">
          <span className="text-[0.625rem] font-medium leading-none tracking-widest">FLOSKEL</span>
        </div>
      </div>
      <div className="space-y-2 first-letter:uppercase">
        <strong className="text-[1.875rem] leading-none text-eidraBlack">{phrase}</strong>
        <p className="text-[1.125rem] leading-[1.3] text-eidraBlack">{explanation}</p>
      </div>
    </div>
  );
};
