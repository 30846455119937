import { useContext, useEffect, useState } from 'react';
import PieChart from './PieChart';
import { useKpiCollection } from '../hooks/useKpiCollection';
import { ThemesKpiPoint, ThemesKpiValue } from '../../../functions/src/common/kpiTypes';
import { SpeechConfigContext } from '../providers/speechConfig';
import ListeningView from './ListeningView';

type Theme = {
  theme: string;
  percentage: number;
};

export function ThemesView() {
  const speechConfig = useContext(SpeechConfigContext);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [themesKpi] = useKpiCollection<ThemesKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Themes',
    initialState: [] as ThemesKpiPoint[],
  });
  const getThemePercentages = (arr: ThemesKpiValue[]): Theme[] => {
    const totalStatmentsCount = arr.reduce((acc, value) => acc + value.statements.length, 0);
    const percentages = arr.map(value => ({
      theme: value.theme,
      percentage: (value.statements.length / totalStatmentsCount) * 100,
    }));

    return percentages as Theme[];
  };

  useEffect(() => {
    if (themesKpi.length === 0) return;

    //combine all values from all kpi points, and remove duplicates
    const allThemes = themesKpi.reduce((acc, value) => {
      return [...acc, ...value.value];
    }, [] as ThemesKpiValue[]);
    const uniqueThemes = Array.from(new Set(allThemes.map(theme => theme.theme))).map(theme => {
      return allThemes.find(t => t.theme === theme) as ThemesKpiValue;
    });
    setThemes(getThemePercentages(uniqueThemes));
  }, [themesKpi]);

  if (!themes || themes.length === 0) {
    return <ListeningView />;
  }

  return (
    <div className="h-full w-full">
      <PieChart
        labels={themes.map(t => t.theme.toString())}
        data={themes.map(t => t.percentage)}
        dataDescriptor={'% of views in category'}
      />
    </div>
  );
}
