import { useContext, useMemo } from 'react';
import { SpeechConfigContext } from '../providers/speechConfig';
import { useKpiCollection } from '../hooks/useKpiCollection';
import { KeywordsSpacyKpiPoint, KeywordsSpacyKpiValue } from '../../../functions/src/common/kpiTypes';
import ListeningView from './ListeningView';

export function KeywordsView() {
  const speechConfig = useContext(SpeechConfigContext);
  const [keywordsKpi, loading] = useKpiCollection<KeywordsSpacyKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'KeywordsSpacy',
    initialState: [] as KeywordsSpacyKpiPoint[],
  });

  const keywords = useMemo(() => {
    const latestKeywordKpi = keywordsKpi[keywordsKpi.length - 1];
    return (
      latestKeywordKpi?.value
        .sort((a: KeywordsSpacyKpiValue, b: KeywordsSpacyKpiValue) => b.occurrences - a.occurrences)
        .slice(0, 20) ?? []
    );
  }, [keywordsKpi]) as KeywordsSpacyKpiValue[];

  if (keywordsKpi.length === 0 || loading || speechConfig === null) {
    return <ListeningView />;
  }

  return (
    <div className="mt-10 flex flex-wrap gap-6">
      {keywords.map((keyword, i) => (
        <Statement contents={keyword} key={i} index={i} />
      ))}
    </div>
  );
}

const Statement = ({ contents }: { contents: KeywordsSpacyKpiValue; index: number }) => {
  return (
    <div className="relative rounded-[1.875rem] bg-eidraWhite px-5 py-2">
      <strong className="text-[1.875rem] text-partyBgColor">{contents.keyword}</strong>
      <span className="absolute right-0 top-0 flex aspect-square h-8 -translate-y-1/2 translate-x-1/4 items-center justify-center rounded-full border-2 border-eidraWhite bg-eidraCream font-medium">
        {contents.occurrences}
      </span>
    </div>
  );
};
