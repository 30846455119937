import { useContext } from 'react';
import { ImgKpiPoint } from '../../../functions/src/common/kpiTypes';
import { SpeechConfigContext } from '../providers/speechConfig';

export const SummaryImageView = ({ imageKpiPoint }: { imageKpiPoint: ImgKpiPoint }) => {
  const speechConfig = useContext(SpeechConfigContext);
  return (
    <div className="bg-green mt-5 rounded-lg p-2">
      <h1 className="font-normal py-2 text-black">{`Bilder från Sverige om ${speechConfig?.speaker} fick bestämma`}</h1>
      <img
        src={imageKpiPoint.value}
        alt={imageKpiPoint.altText ?? 'summary image'}
        className="h-auto w-[50rem] max-w-[90vw]"
      />
    </div>
  );
};
