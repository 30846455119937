import { useContext, useState, useEffect } from 'react';
import { StatisticsKpiValue, StatisticsKpiPoint } from '../../../functions/src/common/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';
import { SpeechConfigContext } from '../providers/speechConfig';
import ListeningView from './ListeningView';

export function EloquenceView() {
  const speechConfig = useContext(SpeechConfigContext);

  const [statistics, setStatistics] = useState<StatisticsKpiValue | null>(null);
  const [statsKpis] = useKpiCollection<StatisticsKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Statistics',
    initialState: [] as StatisticsKpiPoint[],
  });

  useEffect(() => {
    const newLatestStats = statsKpis.at(-1)?.value ?? null;

    if (!newLatestStats) {
      return;
    }
    setStatistics(newLatestStats);
  }, [statsKpis]);

  if (!statistics) {
    return <ListeningView />;
  }
  return (
    <div className="mt-10 space-y-8 px-1">
      <EloquenceItem
        title="Ord per mening"
        value={statistics.avgWordsPerSentence.toFixed(1)}
        oldValue="16.8"
        oldValueLabel="Partilederna 2024 median"
      />
      <EloquenceItem
        title="Unika ord per 100 ord"
        value={statistics.avgUniqueWordsPerHundred.toFixed(0)}
        oldValue="36"
      />
      <EloquenceItem title="Ord per minut" value={statistics.avgWordsPerMinute.toFixed(0)} oldValue="100" />
    </div>
  );
}

export const EloquenceItem = ({
  title,
  value,
  oldValue,
  oldValueLabel,
}: {
  title: string;
  value: string;
  oldValue: string;
  oldValueLabel?: string;
}) => {
  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-col">
        <span className="font-medium leading-none">{title}</span>
        <span className="text-[5.625rem] font-bold leading-none text-partyBgColor">{value}</span>
      </div>
      <div className="flex flex-col text-right">
        <span className="justify-self-start font-medium leading-none opacity-50">{oldValueLabel ?? ''}</span>
        <span className="mt-auto text-[3.125rem] font-bold opacity-30">{oldValue}</span>
      </div>
    </div>
  );
};
