import { useContext, useEffect, useState } from 'react';
import { NumericKpiName, NumericKpiPoint } from '../../../functions/src/common/kpiTypes';
import { SpeechConfigContext } from '../providers/speechConfig';
import { useDocument } from '../hooks/useDocument';

export const SpeechProfileView = () => {
  const speechConfig = useContext(SpeechConfigContext);

  const [numericKpis, setNumericKpis] = useState<{ [key in NumericKpiName]: number }>({
    TransparencyScore: 0,
    GlobalScore: 0,
    InclusivityScore: 0,
    RationalScore: 0,
    HopefulScore: 0,
    // EvidenceScore: 0,
  });

  const { data: transparencyScoreAverage } = useDocument<NumericKpiPoint>(
    `speeches/${speechConfig?.speechId}/TransparencyScore/average`,
  );

  const { data: globalScoreAverage } = useDocument<NumericKpiPoint>(
    `speeches/${speechConfig?.speechId}/GlobalScore/average`,
  );

  const { data: inclusivityScoreAverage } = useDocument<NumericKpiPoint>(
    `speeches/${speechConfig?.speechId}/InclusivityScore/average`,
  );

  const { data: rationalScoreAverage } = useDocument<NumericKpiPoint>(
    `speeches/${speechConfig?.speechId}/RationalScore/average`,
  );

  const { data: hopefulScoreAverage } = useDocument<NumericKpiPoint>(
    `speeches/${speechConfig?.speechId}/HopefulScore/average`,
  );

  // const { data: evidenceScoreAverage } = useDocument<NumericKpiPoint>(
  //   `speeches/${speechConfig?.speechId}/EvidenceScore/average`,
  // );

  useEffect(() => {
    setNumericKpis({
      TransparencyScore: transparencyScoreAverage?.value ?? 0,
      GlobalScore: globalScoreAverage?.value ?? 0,
      InclusivityScore: inclusivityScoreAverage?.value ?? 0,
      RationalScore: rationalScoreAverage?.value ?? 0,
      HopefulScore: hopefulScoreAverage?.value ?? 0,
      // EvidenceScore: evidenceScoreAverage?.value ?? 0,
    });
  }, [
    transparencyScoreAverage,
    globalScoreAverage,
    inclusivityScoreAverage,
    rationalScoreAverage,
    hopefulScoreAverage,
  ]);

  return (
    <div className="mt-7 space-y-7">
      {Object.keys(numericKpis).map(kpi => (
        <div key={kpi} className="space-y-2 px-2">
          <div className="flex justify-between">
            <label className="font-medium">{getScaleLabels(kpi as NumericKpiName).min}</label>
            <label className="font-medium">{getScaleLabels(kpi as NumericKpiName).max}</label>
          </div>
          <div className="relative flex h-4 items-center justify-between rounded-3xl bg-eidraWhite px-2 py-2">
            <span className="inline-block h-1.5 w-0.5 rounded-lg bg-eidraDarkGrey"></span>
            <span className="inline-block h-1.5 w-0.5 rounded-lg bg-eidraDarkGrey"></span>
            <span className="inline-block h-1.5 w-0.5 rounded-lg bg-eidraDarkGrey"></span>
            <span
              className="absolute block aspect-square h-6 -translate-x-1/2 rounded-full bg-partyBgColor shadow-sm transition-all duration-500"
              style={{ left: `${getNormalizedValue(numericKpis[kpi as NumericKpiName]) * 100}%` }}></span>
          </div>
        </div>
      ))}
    </div>
  );
};

function getScaleLabels(kpiName: NumericKpiName) {
  switch (kpiName) {
    case 'TransparencyScore':
      return { min: 'Tvetydig', max: 'Tydlig' }; // Ambiguous, Transparent
    case 'GlobalScore':
      return { min: 'Lokal', max: 'Global' }; // Local, Global
    case 'InclusivityScore':
      return { min: 'Exkluderande', max: 'Inkluderande' }; // Exclusive, Inclusive
    case 'RationalScore':
      return { min: 'Känslobaserad', max: 'Faktabaserad' }; // Emotional, Rational
    case 'HopefulScore':
      return { min: 'Dystopisk', max: 'Hoppfull' }; // Dystopian, Hopeful
    default:
      return { min: '', max: '' };
  }
}

/**
 * Normalize a value between the min and max values of the KPI scale (-10 / 10).
 * @param value - the value of the KPI
 * @param min - the minimum value of the KPI scale
 * @param max - the maximum value of the KPI scale
 */
function getNormalizedValue(value: number, min = -10, max = 10) {
  return (value - min) / (max - min);
}
