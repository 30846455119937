import { format, parse } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SpeechConfig } from '../../../functions/src/common/speechConfig';
import { ChevronRight } from '../icons/chevron-right';
import { Layout } from './Layout';
import { MainTitle } from './typography/MainTitle';
import { Paragraph } from './typography/Paragraph';
import { Typography } from './typography/Typograhy';
import { sv } from 'date-fns/locale';
import { useEffect, useState } from 'react';

type Props = {
  speechConfigs: SpeechConfig[];
};

export const LandingPage = ({ speechConfigs }: Props) => {
  const [searchParams] = useSearchParams();
  const [isTestmode, setIsTestmode] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('test') === 'true') {
      setIsTestmode(true);
    }
  }, [searchParams]);

  const parseDate = (date: string) => {
    return parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  };

  const sortSpeechByDateAsc = (a: SpeechConfig, b: SpeechConfig) => {
    const aDate = parseDate(a.eventStartTime);
    const bDate = parseDate(b.eventStartTime);

    return aDate.getTime() - bDate.getTime();
  };

  const getDateInSwedish = (date: string) => {
    return format(parseDate(date), "d MMMM', kl' HH:mm", { locale: sv });
  };

  const getDayNameInSwedish = (date: string) => {
    return format(parseDate(date), 'EEEE', { locale: sv });
  };

  return (
    <Layout className={'mx-auto pt-8 lg:max-w-2xl'}>
      <Typography>
        <MainTitle>Välkommen till Eidra Klartext</MainTitle>

        <Paragraph>
          Eidra Klartext bearbetar och sammanfattar politikers tal i realtid, extraherar sentimentstatistik, viktiga
          budskap och floskler. Vi försöker hitta de genomgripande narrativen och hur utmaningarna skall mötas.
          <br />
          <br />
          På den här sidan kan du se när våra politiker ska tala, följa analysen i live och ta del av resultat och
          sammanfattningar av alla tal i efterhand. Du är också välkommen att{' '}
          <a
            href="https://arc-almedalen.confetti.events/"
            target="_blank"
            rel="noreferrer noopener"
            className="pointer-events-auto underline">
            besöka vår trädgård i Almedalen
          </a>
          , där talen kommer att strömmas med live-tolkning.
        </Paragraph>
      </Typography>
      <div className="grid grid-cols-1 gap-2 pt-[1.25rem]">
        {speechConfigs &&
          speechConfigs
            .filter(speechConfig => (isTestmode ? true : !speechConfig.speaker.startsWith('TEST')))
            .sort(sortSpeechByDateAsc)
            .map(speechConfig => {
              return (
                <button key={'btn_' + speechConfig.speechId} onClick={() => navigate('/' + speechConfig.speechId)}>
                  <div className={'grid grid-cols-5 gap-3 overflow-hidden rounded-lg border border-eidraDarkCream'}>
                    <div className={'col-span-4 flex px-2 py-4'}>
                      <div className={'col-span-1 shrink-0 grow-0 basis-9'}>
                        <img
                          src={speechConfig.partyIcon}
                          alt={speechConfig.party}
                          className={'inline-block w-8 max-w-full'}
                        />
                      </div>
                      <div className={'ml-2 space-y-2.5 text-left'}>
                        <div className={'flex flex-col gap-0.5'}>
                          <h2 className={'block truncate text-[1.125rem] font-medium leading-none'}>
                            {speechConfig.speaker}
                          </h2>
                          <span className={'block text-[0.75rem] font-light leading-none'}>{speechConfig.party}</span>
                        </div>
                        <Paragraph>
                          {speechConfig.speechEndTime ? (
                            'Analys klar'
                          ) : speechConfig.speechStartTime ? (
                            'Live'
                          ) : (
                            <>
                              <span className={'capitalize'}>{getDayNameInSwedish(speechConfig.eventStartTime)} </span>
                              {getDateInSwedish(speechConfig.eventStartTime)}
                            </>
                          )}
                        </Paragraph>
                      </div>
                    </div>
                    <div className="col-span-1 flex items-center justify-center bg-eidraDarkCream">
                      <span className={'align'}>
                        <ChevronRight />
                      </span>
                    </div>
                  </div>
                </button>
              );
            })}
      </div>
    </Layout>
  );
};

export default LandingPage;
