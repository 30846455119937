import { type ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useKioskMode } from '../hooks/useKiosk';
import { NarrativeView } from './NarrativeView';
import { SummaryImageView } from './SummaryImageView';
import { SpeechProfileView } from './SpeechProfileView';
import { KeywordsView } from './KeywordsView';
import { SummaryTextView } from './SummaryTextView';
import { TakeawayView } from './TakeawayView';
import { ThemesView } from './ThemesView';
import c from 'classnames';
import { useDidMount } from '../utils/useDidMount';
import { VerbiagesView } from './VerbiagesView';
import { SentimentView } from './SentimentView';
import { PromisesView } from './PromisesView';
import { EloquenceView } from './EloquenceView';
import { SummaryStatsView } from './SummaryStatsView';
import { isRecording } from '../utils/elapsedTime';
import { SpeechConfigContext } from '../providers/speechConfig';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import '../styles/swiper.css';
import { ImgKpiPoint, SummaryKpiPoint } from '../../../functions/src/common/kpiTypes';
import { useKpiCollection } from '../hooks/useKpiCollection';

type CarouselSlide = {
  headerTitle: string;
  component: ReactNode;
};

const slides: CarouselSlide[] = [
  { headerTitle: 'Ordflöde analys', component: <SentimentView /> },
  { headerTitle: 'Områden', component: <ThemesView /> },
  { headerTitle: 'Valfläsk', component: <PromisesView /> },
  { headerTitle: 'Floskeltoppen', component: <VerbiagesView /> },
  {
    headerTitle: 'Talprofil',
    component: <SpeechProfileView />,
  },
  { headerTitle: 'Sverigefrågan', component: <TakeawayView /> },
  { headerTitle: 'Narrativ', component: <NarrativeView /> },
  { headerTitle: 'Ordfrekvens', component: <KeywordsView /> },
  { headerTitle: 'Statistik', component: <EloquenceView /> },
];

export const AnalysisCarousel = () => {
  const speechConfig = useContext(SpeechConfigContext);
  const mounted = useDidMount();
  const { isKioskMode } = useKioskMode();
  const autoplay = isKioskMode;

  const [activeSlides, setActiveSlides] = useState<CarouselSlide[]>(slides);

  const [latestSummaryKpiPoint, setLatestSumaryKpiPoint] = useState<SummaryKpiPoint | null>(null);
  const [summaryKpi] = useKpiCollection<SummaryKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'Summary',
    initialState: [] as SummaryKpiPoint[],
  });

  const [imageKpiPoints] = useKpiCollection<ImgKpiPoint[]>({
    speechId: speechConfig?.speechId ?? null,
    kpiName: 'SummaryImg',
    initialState: [] as ImgKpiPoint[],
  });

  useEffect(() => {
    if (summaryKpi.length === 0) return;
    setLatestSumaryKpiPoint(summaryKpi[summaryKpi.length - 1]);
  }, [summaryKpi]);

  const progressCircle = useRef<SVGSVGElement | null>(null);
  const onAutoplayTimeLeft = (s: SwiperRef['swiper'], time: number, progress: number) => {
    if (progressCircle.current === null) return;
    progressCircle.current.style.setProperty('--progress', `${1 - progress}`);
  };

  const modules = autoplay ? [Pagination, Autoplay] : [Pagination];

  useEffect(() => {
    if (latestSummaryKpiPoint === null) {
      setActiveSlides(slides);
    } else if (imageKpiPoints.length > 0 && latestSummaryKpiPoint) {
      setActiveSlides([
        {
          headerTitle: 'Summering',
          component: <SummaryTextView summary={latestSummaryKpiPoint.summary} />,
        } as CarouselSlide,
        {
          headerTitle: 'Sverigebilden',
          component: <SummaryImageView imageKpiPoint={imageKpiPoints[imageKpiPoints.length - 1]} />,
        } as CarouselSlide,
        {
          headerTitle: 'Summering',
          component: <SummaryStatsView summaryKpiPoint={latestSummaryKpiPoint} />,
        } as CarouselSlide,
        ...slides,
      ]);
    } else {
      setActiveSlides([
        {
          headerTitle: 'Summering',
          component: <SummaryTextView summary={latestSummaryKpiPoint.summary} />,
        } as CarouselSlide,
        {
          headerTitle: 'Summering',
          component: <SummaryStatsView summaryKpiPoint={latestSummaryKpiPoint} />,
        } as CarouselSlide,
        ...slides,
      ]);
    }
  }, [speechConfig, latestSummaryKpiPoint, imageKpiPoints]);

  return (
    <div className="flex h-full">
      <Swiper
        modules={modules}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={autoplay ? { delay: 10000, pauseOnMouseEnter: true } : false}
        onAutoplayTimeLeft={onAutoplayTimeLeft}>
        {activeSlides.map((slide, i) => (
          <SwiperSlide key={slide.headerTitle + i}>
            <div className={'flex h-full flex-col'}>
              <div className="flex h-20 items-center px-2.5 text-eidraBlack">
                <h2 className="mt-1 text-[2.5rem] font-bold leading-none">{slide.headerTitle}</h2>
              </div>

              <div
                className={c(
                  'relative mt-[3.9rem] flex-auto transition-opacity duration-[1.5s]',
                  mounted ? 'opacity-100' : 'opacity-0',
                  isRecording(speechConfig) && 'mt-[5rem]',
                )}>
                <div className="pointer-events-auto absolute inset-0 mb-12 overflow-y-auto from-eidraCream to-transparent px-2.5 pb-10 after:fixed after:bottom-0 after:mb-12 after:block after:h-10 after:w-full after:bg-gradient-to-t">
                  {slide.component}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
        </div>
      </Swiper>
    </div>
  );
};
